<template>
  <b-overlay :show="loading" rounded="sm">
    <b-form class="form" @submit.stop.prevent="do_topup">
      <div class="card card-custom card-stretch">
        <div class="card-body">
          <h3 class="font-size-lg text-dark-75 font-weight-bold mb-8">
            เลือกธนาคารปลายทาง
          </h3>
          <div
            class="row"
            :class="{
              'bg-light-danger p-2': dirtyBank && !validateState('bank')
            }"
            v-if="bankList"
          >
            <div
              class="col-md-6 col-lg-4"
              v-for="item in bankList.records"
              v-bind:key="item.bank_id"
            >
              <label class="option">
                <span class="option-control">
                  <span class="radio">
                    <input
                      type="radio"
                      v-model="$v.form.bank.$model"
                      :value="item"
                    />
                    <span></span>
                  </span>
                </span>
                <span class="option-label">
                  <span class="option-head justify-content-start">
                    <div class="symbol symbol-40 flex-shrink-0 mr-4">
                      <div
                        class="symbol-label"
                        :style="
                          `background-image: url('${BANK_URL}${item.bank_img}')`
                        "
                      ></div>
                    </div>
                    <span class="option-title">{{
                      getBankName(item.bank_name)
                    }}</span>
                  </span>
                  <span class="option-body">
                    <div class="mb-1" style="font-size: 1.1rem">
                      เลขบัญชี {{ item.account_no }}
                    </div>
                    <div style="font-size: 1.1rem">
                      ชื่อบัญชี {{ item.account_name }}
                    </div>
                  </span>
                </span>
              </label>
              <div class="fv-plugins-message-container"></div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-lg-8">
              <div class="form-group row">
                <label
                  for="input-amount"
                  class="col-form-label text-right col-md-3 col-4"
                  >ยอดเงินที่โอน</label
                >
                <div class="col-md-6 col-8">
                  <b-form-input
                    id="input-amount"
                    v-model="$v.form.amount.$model"
                    :state="validateState('amount')"
                    type="number"
                    step="0.01"
                    required
                    placeholder=""
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row">
                <label
                  for="date"
                  class="col-form-label text-right col-md-3 col-4"
                  >วันที่โอน</label
                >
                <div class="col-md-6 col-8">
                  <b-form-datepicker
                    id="date"
                    type="date"
                    placeholder="ยังไม่เลือกวันที่"
                    locale="th-TH"
                    label-no-date-selected=""
                    v-model="$v.form.date.$model"
                    class="mb-2"
                    :state="validateState('date')"
                    required
                  ></b-form-datepicker>
                </div>
              </div>
              <div class="form-group row">
                <label
                  for="time"
                  class="col-form-label text-right col-md-3 col-4"
                  >เวลาโอน</label
                >
                <div class="col-md-6 col-8">
                  <b-form-input
                    id="time"
                    type="time"
                    now-button
                    v-model="$v.form.time.$model"
                    class="mb-2"
                    :state="validateState('time')"
                    required
                  ></b-form-input>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-3 col-4 col-form-label text-right">
                  สลิปโอนเงิน
                </label>
                <div class="col-md-6 col-8">
                  <div class="uppy">
                    <div class="uppy-wrapper">
                      <div class="uppy-Root uppy-FileInput-container">
                        <input
                          class="uppy-FileInput-input uppy-input-control"
                          ref="fileInput"
                          type="file"
                          name="files[]"
                          accept="image/*"
                          multiple=""
                          @input="pickFile"
                          id="file"
                          style=""
                        />
                        <label
                          class="uppy-input-label btn btn-light-primary btn-sm btn-bold"
                          for="file"
                        >
                          เลือก สลิปโอนเงิน
                        </label>
                      </div>
                    </div>
                    <div class="uppy-list">
                      <div class="uppy-list-item" v-if="previewImage">
                        <div
                          v-viewer
                          class="border symbol symbol-60 flex-shrink-0 mr-4"
                        >
                          <img
                            style="object-fit: contain"
                            :src="previewImage"
                            height="200"
                            width="200"
                          />
                        </div>
                        <div class="uppy-list-label">
                          {{ file.name }}
                        </div>
                        <span class="uppy-list-remove" @click="removeFile()">
                          <i class="flaticon2-cancel-music"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <span class="form-text text-muted">
                    อัพโหลดไฟล์ไม่เกิน 20MB.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-lg-10 ml-lg-auto">
              <button
                type="submit"
                class="btn btn-success mr-4"
                style="width: 140px"
              >
                เติมเงิน
              </button>
              <router-link
                to="/wallet?cat=2"
                class="btn btn-secondary"
                style="width: 140px"
              >
                ยกเลิก
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </b-overlay>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import dayjs from "dayjs";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CREATE_TOPUP } from "@/graphql/topup";
import { GET_BANKS } from "@/graphql/bank";
import { BANK_URL, getBankName } from "@/config";

export default {
  mixins: [validationMixin],
  name: "topup_create",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `กระเป๋าเงิน`, route: "/wallet" },
      { title: `เติมเงินเข้าระบบ` }
    ]);
  },
  data() {
    return {
      BANK_URL,
      previewImage: null,
      dirtyBank: false,
      loading: false,
      form: {
        bank: "",
        amount: 0,
        file: null,
        date: dayjs("2019-01-25").format("YYYY/MM/DD"),
        time: null
      }
    };
  },
  validations: {
    form: {
      bank: {
        required
      },
      amount: {
        required
      },
      date: {
        required
      },
      time: {
        required
      }
    }
  },
  methods: {
    getBankName,
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async do_topup() {
      this.dirtyBank = true;
      this.$v.form.$touch();
      if (!this.file) {
        this.$message.error("กรุณาแนบสลิป");
        return;
      }
      if (this.$v.form.$anyError) {
        return;
      }

      const amount = this.$v.form.amount.$model;
      const date = this.$v.form.date.$model;
      const time = this.$v.form.time.$model;
      const bank = this.$v.form.bank.$model;
      const datetime = dayjs(date + " " + time);

      const input = {
        amount: Number(amount),
        date: datetime,
        file: this.file,
        bank_id: bank.bank_id
      };
      this.loading = true;
      const {
        data: { createTopup }
      } = await this.$apollo.mutate({
        mutation: CREATE_TOPUP,
        variables: {
          input: input
        }
      });
      if (createTopup.error_list) {
        this.$message.error(createTopup.message);
      } else {
        this.$message.success(createTopup.message);
        this.$router.push({ name: "wallet", query: { cat: 2 } });
      }
      this.loading = false;
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        if (
          file[0]["type"] !== "image/jpeg" &&
          file[0]["type"] !== "image/png"
        ) {
          Swal.fire({
            title: "",
            text: "แนบไฟล์ JPG/PNG เท่านั้น",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          return;
        }
        let reader = new FileReader();
        reader.onload = e => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.file = file[0];
        this.fileValid = !!this.file;
        this.$emit("input", file[0]);
      }
    },
    removeFile() {
      this.file = null;
      this.previewImage = null;
      this.$refs.fileInput.value = null;
    }
  },
  apollo: {
    bankList: {
      query: GET_BANKS
    }
  }
};
</script>
